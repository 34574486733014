
.itemcontainer {
  position: relative;
  width: 100%;

}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: .9s ease;
  opacity: 0;
  position: absolute;
  top: 62%;
}

.middle2 {
  transition: .9s ease;
  opacity: 0;
  position: absolute;
  top: 55%;
  width: 100%;


}

.itemcontainer:hover .image {
  opacity: 1;

}

.itemcontainer:hover .middle {
  opacity: 1;
}

.text {
  background-color: #27262690;
  color: white;
  font-size: 12px;
  padding: 6px 12px;
  text-align: left;
}