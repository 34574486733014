
.flexgrow1{flex-grow :1;}
.flexgrow2{flex-grow :2;}
.flexgrow3{flex-grow :3;}
.flexgrow4{flex-grow :4;}
.flexgrow5{flex-grow :5;}
.flexgrow6{flex-grow :6;}
.flexgrow7{flex-grow :6;}
.flexgrow8{flex-grow :6;}
.flexgrow9{flex-grow :6;}

/* @media only screen and (max-width: 768px) {

} */



.Container{
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  /* max-width: 1400px; */
}
.SmallItemContainer{
  display: flex;
  flex-direction: column;
  background-color: #FFF;

  flex-direction: column;
  justify-content: center;
  width: 100%;

}
.MaxItemContainer{
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  justify-content: center;
  width: 80%;
  padding :0px 10%;

}


.Homecontainer{
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  margin: 0px 10%;
  flex-direction: column;
  justify-content: center;

}

.HomeMaxcontainer{
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  margin: 0px 15%;
  flex-direction: column;
  justify-content: center;

}

.HomeBestcontainer{
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  width: 80%;
  flex-direction: column;
  justify-content: center;
  padding : 0px 10%;
}
.HomePickcontainer{
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  width: 80%;
  flex-direction: column;
  justify-content: center;
  padding : 0px 10%;
}

.HomeNewcontainer{  
  display: flex;
  flex-direction: column;
  background-color: #EDEDED;
  width: 80%;
  flex-direction: column;
  justify-content: center;
  padding : 0px 10%;
}
.HomeNewMaxcontainer{  
  display: flex;
  flex-direction: column;
  background-color: #EDEDED;
  width: 70%;
  flex-direction: column;
  justify-content: center;
  padding : 0px 15%;
}

.ProductContainer{
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #FFF;

  flex-direction: column;
  justify-content: center;

}
.MaxProductContainer{
  display: flex;
  flex-direction: column;
  width: 80%;
  padding :0px 10%;
  background-color: #FFF;

  flex-direction: column;
  justify-content: center;

}


.Box{
  box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09)
}
.grow{
  background-color: gray;
  z-index: 3;
  position: absolute;
  top:0;

}


.TestImg{
  background: linear-gradient(-45deg, #313131 50%, #1A1A1A 50%);
  border-radius : 15px;
}

.InputCommon{
  border:none; 
  background-color:unset; 
  width:100%;
  padding-left:unset;

}

.wrapper {
  width: 45px;
  border-radius:50px;
  position: absolute;
  top: 10px;
  right: 3px;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.NewButton{
  background-color: rgb(255, 0, 0);
  border-radius: 50px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.NewButtonText{
  color :#fff;
  font-size:9px;
}

.MdButton{
  background-color: rgb(43, 0, 255);
  border-radius: 50px;
  height: 30px;
  width: 30px;
  display: flex;
  margin-top:5px;
  justify-content: center;
  align-items: center;
}
.MdButtonText{
  color :#fff;
  font-size:9px;
}

.BestButton{
  background-color: yellow;
  border-radius: 50px;
  height: 30px;
  width: 30px;
  margin-top:5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.BestButtonText{
  color :#000;
  font-size:9px;
}

.SaleButton{
  background-color: green;
  border-radius: 50px;
  height: 30px;
  width: 30px;
  margin-top:5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SaleButtonText{
  color :#fff;
  font-size:9px;
}

.ribbontext {
    font: 14px Pretendard-Regular;
    color: #fff;
}
  

.ribbontext2 {
  font: 14px Pretendard-Regular;
  color: #000;
}

.ribbontext3 {
  font: 10px Pretendard-Regular;
  color: #000;
}





.MainImg img {
  transition: all 0.2s linear;

}
/* .MainImg:hover img {
  transform: scale(1.4);
} */
.swiper-scrollbar{
  display: none;
}

@keyframes blink-effect {
  50% {
    opacity: 0;
  }
}
@keyframes box-ani {
	0% { transform: translate(0, 0); }
	30% { transform: translate(0px, -3px); }
}

.bannerblink{
  animation: blink-effect 1s linear infinite;
}


.headerblink{
  color: #fff;
  background-color :#fb5555;
  padding: 3px 6px 4px;
  font-size: 12px;
  position: absolute;
  top: 15px;
  right: 154px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
  animation-duration: .2s;
  animation-name: point-move;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  min-width: 50px;
  text-align: center;
  /* animation: blink-effect 2s step-end infinite; */
  animation: box-ani 1s linear infinite;


}
.maxheaderblink{
  color: #fff;
  background-color :#fb5555;
  padding: 3px 6px 4px;
  font-size: 12px;
  position: absolute;
  top: 15px;
  right: 23%;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
  animation-duration: .2s;
  animation-name: point-move;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  min-width: 50px;
  text-align: center;
  /* animation: blink-effect 2s step-end infinite; */
  animation: box-ani 1s linear infinite;
}

.headerblink ::after {
  content: "";
  display: block;
  width: 0px;
  height: 0px;
  position: absolute;
  transform: rotate(45deg);
  border-top-color: rgba(35,35,35,0);
  border-left-color: rgba(35,35,35,0);
  left: 50%;
  bottom: -2px;
  margin-left: -2px;
}