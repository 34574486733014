




.site-mobile-footer{
  background: #BFBFBF;
  width: 100%;
}
.bottomapply{
  bottom:80px;
}
.bottomnoapply{
  bottom:0px;
}

.site-mobile-footer2{
  height: 80px;
  background: #fff;
  width: 100%;
  
  bottom: 0%;
}

.site-mobile-footer2 .buttonview{
  display: flex;
  flex-direction: row;
  height:100%;
}

.site-mobile-footer2 .buttonview .button
{
  flex-grow: 1;
  display: flex;
  flex-direction : column;
  justify-content: center;
  align-items: center;
  
}

.buttonEnableText{
  font-size:12px;
  color :#0F4C81
}
.buttonDisableText{
  font-size:12px;
  color :#D9D9D9 
}





.imgicon{
  width: 30px;
  height: 30px;
}



.site-mobile-footer .content{
  font-size :13px;
  letter-spacing: -0.32px;
  color: #7b7979;
  line-height: 25px;
}
